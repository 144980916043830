import React from "react";
import UserProfile from "../components/users/components/UserProfile";

export default function ProfilePage() {
    return(
        <React.Fragment>
            <UserProfile />
        </React.Fragment>
        
    )
}