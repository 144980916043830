import React from "react";
import LoginForm from "../components/auth/LoginForm";

export default function Login() {
    return(
        <React.Fragment>
            <div className="flex justify-center">
            <LoginForm />
            </div>
            
        </React.Fragment>
        
    )
}